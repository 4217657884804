var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper msg-wrap"
  }, [_c('div', [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  })], 1), _vm._m(0), _vm.screenShotList.length == 0 ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" No records found. ")]) : _vm._e(), _c('div', [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.screenShotList, function (item, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mt-4",
      attrs: {
        "xl": "4",
        "lg": "6",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "mb-2 screen-shot-name"
    }, [_vm._v(_vm._s(item.name))]), _c('img', {
      staticClass: "img-align screen-shot-card",
      attrs: {
        "src": item.url
      },
      on: {
        "click": function click($event) {
          return _vm.openModal(item, 'viewScreenShotModel');
        }
      }
    })]);
  }), 1)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "viewScreenShotModel",
      "modal-class": "client-details-modal",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('img', {
    staticClass: "img-align",
    attrs: {
      "src": _vm.modalInfo.imgUrl
    }
  })])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("View Screen Shots.")])]);
}]

export { render, staticRenderFns }