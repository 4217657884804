<template>
  <div class="page-wrapper msg-wrap">
    <div>
      <breadcrumb :items="breadcrumbItems" />
    </div>
    <div class="page-title">
      <h1>View Screen Shots.</h1>
    </div>
    <div class="text-center" v-if="screenShotList.length == 0">
      No records found.
    </div>
    <div>
      <b-row no-gutters>
        <b-col v-for="(item, index) in screenShotList" :key="index" xl="4" lg="6" md="6" class="mt-4">
          <div class="mb-2 screen-shot-name">{{item.name}}</div>
          <img :src="item.url"
               @click="openModal(item,  'viewScreenShotModel')"
               class="img-align screen-shot-card" />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-modal
          id="viewScreenShotModel"
          modal-class="client-details-modal"
          size="lg"
          hide-footer>
        <template #modal-header>
          <div class="view-clients-header">
            <b-button variant="close-btn" @click="closeModal">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <div>
          <img :src="modalInfo.imgUrl"
               class="img-align" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ViewScreenShots",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  mounted() {
    let app = this
    app.getScreenShotList ();
  },
  data () {
    return {
      breadcrumbItems: [
        { title: "Enrollments", active: false, path: {name: 'PendingEnrollmentList'}},
        { title: "ViewScreenShots", active: true }
      ],
      screenShotList: [],
      modalInfo: {}
    }
  },
  methods: {
    getScreenShotList () {
      let app = this
      let encodedId = window.btoa(unescape(encodeURIComponent(app.id)))
      let loader = this.$loading.show();
      const url = `v2/get-pending-enrollment-screenshots/${encodedId}`
      return axios
          .get(url)
          .then((response) => {
            app.screenShotList = response.data.data
            loader.hide()
          }).catch((err) => {
            this.$notify(err.response.data.message, "error");
          }).finally(() => {
            loader.hide();
          });
    },
    openModal(data,  modalId) {
      let app = this
      app.modalInfo = {}
      app.modalInfo['imgUrl']= data.url
      this.$bvModal.show(modalId);
    },
    closeModal() {
      this.$bvModal.hide("viewScreenShotModel");
    }
  }
}
</script>

<style scoped>
.screen-shot-card {
  background: #fff;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 15px #3890c714;
  box-shadow: 0 0 15px #3890c714;
  padding: 10px;
  cursor: pointer;
}
.screen-shot-card:hover {
  transform: translateY(-5px);
  transition: 0.2s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-align {
  width: 100% !important;
}
.screen-shot-name {
  font-size: 18px;
  font-weight: 700;
  color: #2C3F58;
}
</style>
